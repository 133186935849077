.socials__list {
  font-size: 0;
  margin-bottom: 0;
}

.socials__item {
  font-size: 35px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  a {
    display: flex;
    align-items: center;
  }
}

.socials__link {
  color: $white;
  &:hover {
    color: $flatcar-blue;
  }
}
