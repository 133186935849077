.article {
  .article__hero-image {
    position: relative;
    height: 0;
    padding-bottom: 41.89%;
    overflow: hidden;
    margin-bottom: 76px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 50%;
      margin-bottom: 20px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  a {
    text-decoration:none;
    color: $flatcar-darker-blue;
    font-weight: bold;
    transition: background 0.5s cubic-bezier(.33,.66,.66,1);//font

    &:hover {
      text-decoration: underline;

    }
  }

  ::selection {
    background: $flatcar-dark-blue;
    color: white;
  }

  .article__product-link {
    position: relative;
    display: block;
    font-size: 24px;

    @include media-breakpoint-down(sm) {
      font-size: 19px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0 0 0 6px $flatcar-dark-blue;
    }

    .btn-arrow__icon {
      width: 1em;
      right: 1em;
    }
    > img {
      width: 100%;
      height: auto;
    }

    span {
      transition: color 0.3s;
    }
  }

  .article__product-link:hover {
    @include media-breakpoint-up(md) {
      span {
        color: $yellow;
      }
    }
  }

  .article-main {
    width: 80%;
    margin-top: 80px;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-top: 10px;
    }

    .article-content {
      width: 90%;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      h1:not(:first-child), h2:not(:first-child), h3:not(:first-child) {
        margin-top: 50px;
      }
    }
  }

  .article__author {
    color: $gray-600;

    p {
      font-size: .9rem;
    }
  }

  .section-title {
    margin-bottom: 30px;
  }

  .authors {
    margin-bottom: 40px;
  }
}

.article__related {
  background-color: $gray-100;
  padding: 4rem 0 8rem 0;

  @include media-breakpoint-down(sm) {
    margin-top: 80px;
  }

  h2 {
    margin-bottom: 1.3em;
  }

  .card {
    padding: 0;
    background: $white;
    border: 1px solid $gray-200;
    font-size: 1rem;
    max-width: 320px;
    min-height: 340px;
    height: 100%;

    a {
      color: $black;
    }

    a:hover {
      color: $flatcar-blue;
      text-decoration: none;
    }

    .card-img-top {
      margin: auto;
      height: 180px;
      width: 100%;
      object-fit: cover;
    }

    .card-body {
      padding: 22px;
      padding-bottom: 44px;
    }

    .card-title {
      margin: 0;
    }

    .card-date {
      font-size: .9rem;
      color: $gray-600;
    }
  }
}
