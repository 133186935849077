.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0.06;
    pointer-events: none;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        user-select: none;
    }
}
