
.navbar {
  margin-top: 22px;
  transition: all 0.2s linear;

  .navbar_contact_button {
    @extend .btn-blue;
  }
}

#navbarToggler {
    margin-top: 0.1rem;
}

.navbar-brand {
  margin-right: 0;

  img {
    max-height: 85px;
    transition: all 0.3s ease;
  }
}

.nav-link {
  color: $white;
  line-height: 2em;
  text-transform: uppercase;
  font-weight: $font-weight-extra-bold;

  @include media-breakpoint-down(sm) {
    position: relative;
    display: inline-block;
    line-height: 1.5em;
  }
}

.navbar-nav {

  > .nav-item {
    position: relative;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      width: 100%;
      box-shadow: 0 1px 0 0 rgba($white, 0.1);
      overflow: hidden;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: calc(100% - 0.25em);
      left: -4px;
      right: -4px;
      height: 2px;
      background: $yellow;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.3s;
    }
  }

  > .nav-item:hover:before {
    @include media-breakpoint-up(md) {
      transform: scaleX(1);
    }
  }
}

.nav-link:hover, .nav-link:focus {
  color: $white;
}

.dropdown-toggle:after {
  width: 0.5em;
  height: 0.5em;
  border-right: 2px solid $white;
  border-bottom: 2px solid $white;
  border-top: none;
  border-left: none;
  margin-left: 0.83em;
  transform: rotate(45deg) translate(-0.17em, 0.17em);

  @include media-breakpoint-down(sm) {
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotate(45deg) translate(0em, -0.2em);
  }
}

.nav-item.dropdown  {
  &:hover,&.focus {
    @include media-breakpoint-up(md) {
      > .dropdown-menu {
        display: block;
      }
    }
  }
}

.nav-link_selected {
  + .dropdown-menu {
    display: block;
    min-width: auto;

    @include media-breakpoint-down(sm) {
      max-height: 800px;
      pointer-events: auto;
      margin-top: 24px;
    }

    .dropdown-item__text {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.nav-item {
  font-size: 1rem;
}

.nav-item + .nav-item {
  margin-left: 66px;

  @include media-breakpoint-up(xxl) {
    margin-left: 3.44vw;
  }

  @include media-breakpoint-down(lg) {
    margin-left: 54px;
  }

  @include media-breakpoint-down(md) {
    margin-left: 2.5vw;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-top: 26px;
  }
}

.dropdown-item-post, .dropdown-item-pre {
  width: 100%;
  padding: 0 $dropdown-item-padding-x;
  font-size: .9em;

  a {
    color: white;
    box-shadow: inset 0 -3px 0 #fff200;
  }
}

.dropdown-item-pre {
  padding-bottom: 1em;
}

.dropdown-item-post {
  text-align: right;
  padding-top: 1em;
}

.navbar-expand-md {
  .navbar-nav {
    .nav-link {
      @include media-breakpoint-up(md) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

body:not(.mobile-menu_open) {
  nav {
    &.dark {
      .nav-link, .nav-link:hover, .nav-link:focus {
        color: $black;
      }

      .dropdown-toggle:after {
        border-right: 2px solid $black;
        border-bottom: 2px solid $black;
      }

      .navbar_contact_button {
        @extend .btn-black;
      }

      .navbar-toggler {
        > span {
          background: $black;

          &:before {
            box-shadow: 0 8px $black;
          }

          &:after {
            box-shadow: 0 16px $black;
          }
        }
      }
    }
  }
}
