.banner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  text-align: center;

  .banner-bg {
    overflow: hidden;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .animated-svg {
      position: absolute;
      bottom: 0;
      opacity: 0;
    }

    @keyframes fadeInOut {
      0%, 100% {
        opacity: 0.0;
      }
      50% {
        opacity: 0.4;
      }
    }

    .animated-svg {
      animation: fadeInOut 3.5s ease-in-out infinite;
    }

    /* Apply staggered delay to each svg */
    .animated-svg:nth-child(1) { animation-delay: 0s; }
    .animated-svg:nth-child(2) { animation-delay: 0.5s; }
    .animated-svg:nth-child(3) { animation-delay: 1s; }
    .animated-svg:nth-child(4) { animation-delay: 1.5s; }
    .animated-svg:nth-child(5) { animation-delay: 2s; }
    .animated-svg:nth-child(6) { animation-delay: 2.5s; }
    .animated-svg:nth-child(7) { animation-delay: 3s; }
    .animated-svg:nth-child(8) { animation-delay: 3.5s; }
    .animated-svg:nth-child(9) { animation-delay: 4s; }
  }

  h1 {
    font-size: 3.6rem;
    font-family: "Overpass Mono", sans-serif;
    font-weight: 600;
    font-style: normal;
    line-height: 0.9;
    margin-left: auto;
    margin-right: auto;
    max-width: 50rem;
    text-shadow: 0 0 10px #000022, 0 0 20px #000022, 0 0 40px #000022;

    @include media-breakpoint-down(md) {
      font-size: xxx-large;
    }
  }

  h2 {
    font-size: 1.4rem;
    font-weight: normal;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
      max-width: 550px;
    }
  }

  .hero-icon {
    max-height: 120px;
    width: auto;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }

  .footnote {
      margin-top: 0.4rem;
      font-size: 0.9rem;
      text-shadow: 0 0 10px #000022, 0 0 20px #000022, 0 0 40px #000022;
      span {
          color: white;
          weight: bold;
      }
  }
}

.content {
  font-size: 1.5rem;
  margin-top: 25px;
}

.section-image {
  @include media-breakpoint-down(sm) {
    margin-bottom: 68px;
  }
}
