.grid-statements {
  padding-top: 120px;
  background: $gray-100;

  .container {
    @include media-breakpoint-up(md) {
      width: 80%;
      max-width: 1000px;
    }
  }

  .title {
    text-align: center;
    width: 100%;
    font-size: 2.5rem;

    @include media-breakpoint-up(md) {
      max-width: 650px;
      font-size: 3rem;
    }
  }

  .card {
    background: $white;
    padding: 44px 22px;
    height: 100%;
    text-align: center;

    h1 {
      font-size: 1.5rem;
      min-height: 80px;
      margin: 0 auto 0 auto;

      @include media-breakpoint-up(md) {
        width: 80%;
      }
    }

    h2 {
      font-size: 1.2rem;
    }

    .card-body {
      margin: 0;
      padding: 0;

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 22px;

          &:last-child {
            margin-bottom: 44px;
          }
        }
      }
    }
  }

  .col:last-child:nth-child(odd) {
    flex: 0 0 100% !important;
    max-width: 100%;
  }
}
