.fstandard {
  .section-header {

    width: 70%;

    @include media-breakpoint-down(md) {
      width: 95%;
    }

    .heading {
      font-size: 1.2rem;
      font-weight: normal;
      color: $flatcar-blue;
    }

    .title {
      font-size: 2.5rem;
    }

    .description {
      color: $gray-800;
    }

    p {
      font-size: 1.2rem;
    }
  }
}
