#fastSearch {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 20px;
  text-align: left;

  .search-contents {
    z-index: 5;
    position: relative;
    width: 100%;
    margin: 0 auto 0 auto;
  }

  input {
    padding: 5px 10px;
    width: 100%;
    background-color: $white;
    border: 1px solid $gray-400;
    text-align: left;
    outline-width: 0;

    &:hover, &:focus {
      border: 1px solid $black;
      border-radius: 0;
      outline: 3px solid rgba(220,221,224,.45);
      box-shadow: 0 0 3pt 2pt rgba(220,221,224,.45)
    }
  }

  #searchResults {
    border: 1px solid $black;
    visibility: hidden;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    background: $white;
    max-height: 60vh;
    overflow-y: auto;

    @include media-breakpoint-down(md) {
      max-width: 50vh;
    }

    ul {
      padding-inline-start: unset;
    }

    li {
      padding: 10px;
      list-style: none;
      background-color: $white;

      &.title {
        font-size: 1.1em;
        margin-bottom: 10px;
        display: inline-block;
      }

      &:hover, &:focus {
        background-color: #e0e0e0;
      }

      &.no-results {
        color: $gray-700;
        font-size: .9em;
        padding: 10px;

        &:hover, &:focus {
          background-color: unset;
        }
      }
    }

    a {
      display: inline-block;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;

      span.contents {
        font-size: .9em;
        white-space: nowrap;
        color: $gray-800;
      }

      span.title {
        font-weight: bolder;
      }

      span.badge {
        padding-top: 5px;
      }

      &:hover, &:focus {
        color: $black;
        outline: unset;

        span.title {
          color: $flatcar-blue;
        }
      }
    }
  }
}
